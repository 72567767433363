// https://docs.usercentrics.com/#/cmp-v2-ui-api
import { qs, qsa } from "./utils"

const getService = (serviceId) => {
  return new Promise((resolve, reject) => {
    waitFor(
      () =>
        Object.prototype.hasOwnProperty.call(window, "UC_UI") &&
        window.UC_UI.isInitialized(),
    )
      .then(() => {
        const service = window.UC_UI.getServicesBaseInfo().find(
          (s) => s["id"] === serviceId,
        )
        if (service) {
          resolve(service)
        } else {
          reject()
        }
      })
      .catch(() => reject())
  })
}

const INIT_CLASS = "cmp-embed__initializing"
const CONSENT_CLASS = "cmp-embed__consent"
const ERROR_CLASS = "cmp-embed__error"

const initEmbeds = () => {
  qsa(".cmp-embed").forEach((el) => {
    // reset
    el.classList.remove(INIT_CLASS)
    el.classList.remove(CONSENT_CLASS)
    el.classList.remove(ERROR_CLASS)
    qsa(":not(template)", el).forEach((el) => el.remove())

    el.classList.add(INIT_CLASS)
    getService(el.dataset["serviceId"])
      .then((service) => {
        if (service && service.consent["status"] === true) {
          el.classList.remove(INIT_CLASS)
          const template = qs("template.content", el)
          const clone = template.content.cloneNode(true)

          Object.values(clone.children).forEach((item) => {
            el.appendChild(item)
            if (item.tagName === "SCRIPT") {
              const newScriptEl = document.createElement("script")
              for (const attr of item.attributes) {
                newScriptEl.setAttribute(attr.name, attr.value)
              }
              const scriptText = document.createTextNode(item.innerHTML)
              newScriptEl.appendChild(scriptText)
              item.replaceWith(newScriptEl)
            }
          })

          window.dispatchEvent(new Event("cmp-embed-unwrapped"))
        } else if (service && service.consent["status"] == false) {
          el.classList.remove(INIT_CLASS)
          el.classList.add(CONSENT_CLASS)

          const container = document.createElement("div")
          const template = qs("template.consent", el)
          const clone = template.content.cloneNode(true)

          qsa(".cmp-embed__provider-name", clone).forEach((el) => {
            el.textContent = service.name
          })

          qsa(".cmp-embed__policy-link", clone).forEach((btn) => {
            btn.addEventListener("click", (e) => {
              e.preventDefault()
              window.UC_UI.showSecondLayer(service.id)
            })
          })

          qsa(".cmp-embed__accept-button", clone).forEach((btn) => {
            btn.addEventListener("click", (e) => {
              e.preventDefault()
              window.UC_UI.acceptService(service.id).then(() => {
                initEmbeds()
              })
            })
          })

          container.appendChild(clone)
          el.appendChild(container)
        }

        window.dispatchEvent(new Event("cmp-embed-loaded"))
      })
      .catch((error) => {
        el.classList.remove(INIT_CLASS)
        el.classList.add(ERROR_CLASS)

        const template = qs("template.error", el)
        const clone = template.content.cloneNode(true)
        el.appendChild(clone)

        if (error) {
          throw error
        }
      })
  })
}

/**
 * Function to wait for predicates.
 * @param {function() : Boolean} predicate - A function that returns a bool
 * @param {Number} [timeout] - Optional maximum waiting time in ms after rejected
 * https://gist.github.com/chrisjhoughton/7890239?permalink_comment_id=4411125#gistcomment-4411125
 */
function waitFor(predicate, timeout) {
  return new Promise((resolve, reject) => {
    const check = () => {
      if (!predicate()) return
      clearInterval(interval)
      resolve()
    }
    const interval = setInterval(check, 100)
    check()

    if (!timeout) return
    setTimeout(() => {
      clearInterval(interval)
      reject()
    }, timeout)
  })
}

export { initEmbeds, waitFor }
