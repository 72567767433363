import { qsa, qs, onReady } from "./utils"

const filterOverlayToggle = document.getElementById("filter-overlay-toggle")
const filterOverlay = document.getElementById("filter-overlay")

if (filterOverlayToggle && filterOverlay) {
  const filterOverlayIsOpen = window.sessionStorage.getItem("filterOverlay")
  if (filterOverlayIsOpen === "1") filterOverlayToggle.checked = true
  onReady(() => {
    filterOverlay.classList.add("animated")
  })

  filterOverlayToggle.addEventListener("change", (e) => {
    window.sessionStorage.setItem("filterOverlay", e.target.checked ? 1 : 0)
  })
}

export const initFilter = () => {
  const toggleAllButtons = qsa("[data-toggle-all]")
  const form = qs("[data-filter-form]")

  if (!form || !toggleAllButtons) return

  for (const toggleAllButton of toggleAllButtons) {
    const siblings = qsa(
      "label.filter__label input",
      toggleAllButton.parentElement,
    )

    function checkSiblings(status) {
      for (const sibling of siblings) {
        sibling.checked = status
      }
    }

    function areSiblingsChecked() {
      return Array.from(siblings).every((sibling) => sibling.checked)
    }

    toggleAllButton.classList.toggle("active", areSiblingsChecked())

    toggleAllButton.addEventListener(
      "click",
      (e) => {
        e.preventDefault()

        if (toggleAllButton.classList.contains("active")) {
          checkSiblings(false)
        } else {
          checkSiblings(true)
        }

        toggleAllButton.classList.toggle("active", areSiblingsChecked())

        form.requestSubmit()
      },
      false,
    )
  }

  form.addEventListener("change", () => {
    form.requestSubmit()
  })
}
