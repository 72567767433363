/* global htmx */
import { initEmbeds } from "./cmp-embeds"

export const initForms = () => {
  const hx = document.querySelector(".hx-form")
  if (hx) {
    document.body.addEventListener("htmx:afterSwap", (e) => {
      initEmbeds()
    })
  }
}
